'use strict';

import sendForm from './modules/sendForm';
import modal from './modules/modal';
import menu from './modules/menu';


document.addEventListener('DOMContentLoaded', () => {

    modal('.header__button', '.services__item', '.overlay', 'overlay_show', '.modal__close', '#modal', 'modal_active', '.form__hidden');

    sendForm('.form', '.form__button', 'form__loader', '.modal__close', '.overlay', 'overlay_show', '.modal__info', 'modal__info_active');

    menu('.header__wrapper', '.header__menu-item', '.header__hamburger', 'header__wrapper_active', 'header__hamburger_active', '.header__button');
});