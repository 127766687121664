function menu(menuContainer, itemMenu, hamburgerElement, menuClassActive, hamburgerClassActive, formButtonSelector) {
    const menu = document.querySelector(menuContainer),
    menuItem = document.querySelectorAll(itemMenu),
    formButton = document.querySelector(formButtonSelector),
    hamburger = document.querySelector(hamburgerElement);

    hamburger.addEventListener('click', () => {
        hamburger.classList.toggle(hamburgerClassActive);
        menu.classList.toggle(menuClassActive);
    });

    menuItem.forEach(item => {
        item.addEventListener('click', () => {
            hamburger.classList.toggle(hamburgerClassActive);
            menu.classList.toggle(menuClassActive);
        })
    })

    if ((window.matchMedia('(max-width: 575px)')).matches) {
        formButton.addEventListener('click', () => {
            hamburger.classList.toggle(hamburgerClassActive);
            menu.classList.toggle(menuClassActive);
        });
    }
}

export default menu;

