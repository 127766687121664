function sendForm(formSelector, btnSubmit, loaderSelector, buttonClose, overlaySelector, classActiveOverlay, modalElementSelector, classActiveElement) {
    const forms = document.querySelectorAll(formSelector),
        //   buttonSubmit = document.querySelector(btnSubmit),
          windowElement = document.querySelector(modalElementSelector),
          buttonCloseElements = document.querySelectorAll(buttonClose);


    const messageObj = {
            success: [`<div class="modal__message">Ваше обращение отправлено, скоро администратор свяжется с Вами</div>`, 'rgba(38,208,26, 1)'],
            error: [`<div class="modal__message">Не удалось отправить Ваше обращение</div>`, 'rgba(219,0,0, 1)']
          };
    
    
    forms.forEach(form => {
        form.addEventListener('submit', (e) => {
            e.preventDefault();
    
            form.querySelector(btnSubmit).innerHTML = `<span class="${loaderSelector}"></span>`;
    
            const formData = new FormData(form);
            send(formData, form);
        });
    });
        

    async function send(formData, form) {
        let response = await fetch('./mailer/smart.php', {
            method: 'POST',
            body: formData,
        });

        if (response.ok) {
            let result = await response.json();
            if(result.message != "Ошибка!") {
                form.reset();
                openModal(overlaySelector, messageObj.success);
            }
            else {
                openModal(overlaySelector, messageObj.error);
            }
            
            form.querySelector(btnSubmit).innerHTML = `Отправить анкету`;
        } else {
            form.querySelector(btnSubmit).innerHTML = `Отправить анкету`;
            openModal(overlaySelector, messageObj.error);
        }
    }

    buttonCloseElements.forEach(btnCloseItem => {
        btnCloseItem.addEventListener('click', () => {
            closeModal(overlaySelector);            
        });
    })

    document.addEventListener('keydown', (e) => {
        const modalWindow = document.querySelector(overlaySelector);
        if (e.code === "Escape" && modalWindow.classList.contains(classActiveOverlay)) {
            console.log('type');
            closeModal(overlaySelector);
        }
    });

    function openModal(modalSelector, messageModal) {
        const modalWindow = document.querySelector(modalSelector),
              modalInfoElement = document.querySelector('.modal__message');

        document.querySelector('#modal').classList.remove('modal_active');
        modalInfoElement.innerHTML = messageModal[0];
        windowElement.style.border = `2px solid ${messageModal[1]}`;
        // document.querySelector('.modal').style.borderColor = messageModal[1];
        modalWindow.classList.add(classActiveOverlay);
        windowElement.classList.add(classActiveElement);
        document.body.style.overflow = 'hidden';
        document.querySelector('#modal').querySelector('.form').querySelector('.form__hidden').value = 'Услуга не выбрана';
    }

    function closeModal(modalSelector) {
        const modalWindow = document.querySelector(modalSelector);
        modalWindow.classList.remove(classActiveOverlay);
        windowElement.classList.remove(classActiveElement);
        document.body.style.overflow = '';
    }        
}

export default sendForm;