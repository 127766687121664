function modal(headerButtonSelector, servicesSelector, overlaySelector, classActiveOverlay, btnCloseSelector, modalElementSelector, modalElementActive, serviceHiddenSelector) {
    const headerButton = document.querySelector(headerButtonSelector),
          servicesItems = document.querySelectorAll(servicesSelector),
          modalWindow = document.querySelector(overlaySelector),
          btnClose = document.querySelector(btnCloseSelector),
          modalElement = document.querySelector(modalElementSelector);
    

    let service;

    headerButton.addEventListener('click', () => {
        openModals(overlaySelector);
    })

    servicesItems.forEach(item => {
        item.addEventListener('click', (e) => {
            service = e.currentTarget.querySelector('.services__item-title').textContent.trim();

            modalElement.querySelector('.form').querySelector(serviceHiddenSelector).value = service;
            openModals(overlaySelector);
        });
    });

    btnClose.addEventListener('click', () => {
        closeModals(overlaySelector);
    });

    document.addEventListener('keydown', (e) => {        
        if (e.code === "Escape" && modalWindow.classList.contains(classActiveOverlay)) {
            closeModals(overlaySelector);
        }
    });

    function openModals(modalSelector) {
        const modalWindow = document.querySelector(modalSelector);
        modalWindow.classList.add(classActiveOverlay);
        modalElement.classList.add(modalElementActive);
        document.body.style.overflow = 'hidden';
    }

    function closeModals(modalSelector) {
        const modalWindow = document.querySelector(modalSelector);
        modalWindow.classList.remove(classActiveOverlay);
        modalElement.classList.remove(modalElementActive);
        document.body.style.overflow = '';
        modalElement.querySelector('.form').querySelector(serviceHiddenSelector).value = 'Услуга не выбрана';
        document.querySelector('.modal__info').classList.remove('modal__info_active');
    }
}

export default modal;